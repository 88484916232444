import React from 'react';
import { Grid, Cell } from '@trbl/react-css-grid';
import ContentBlock from '../ContentBlock';
import ShapedImage from '../ShapedImage';
import CTACard from '../CTACard';
import Card from '../Card';

const BTFMachines = React.memo(({ CTA, machines, blockImage, services }) => {
  return (
    <>
      <section>
        <Grid>
          <Cell
            hStart={5}
            hSpan={9}
            hStartM={5}
            hSpanM={11}
            hStartS={2}
            hSpanS={14}
          >
            <ContentBlock
              body="State of the art machines to meet all your fabrication needs. Browse through the specifications to learn more about how we can support your next project."
              headerAlign="center"
              bodyAlign="left"
            />
          </Cell>
        </Grid>
      </section>
      <section>
        <Grid
          id="machines--machine-list"
        >
          <Cell
            hStart={5}
            hSpan={10}
            hStartL={4}
            hSpanL={12}
            hStartM={3}
            hSpanM={12}
            hStartS={3}
            hSpanS={12}
          >
            <Grid>
              {machines.nodes.map(({
                childMarkdownRemark: {
                  fields: { slug },
                  frontmatter: {
                    title,
                    photo,
                  },
                },
              }) => {
                return (
                  <Cell
                    hSpan={3}
                    hSpanL={4}
                    hSpanM={4}
                    hSpanS={12}
                  >
                    <Card
                      name={title}
                      slug={`/machines${slug}`}
                      image={photo}
                    />
                  </Cell>
                )
              })}
            </Grid>
          </Cell>
        </Grid>
      </section>
      <section>
        <Grid
          id="machines--featured"
        >
          <Cell
            hSpan={16}
            id="machines--image-divider"
          >
            <ShapedImage
              color="green"
              backgroundImage={blockImage}
            />
          </Cell>
          <Cell
            hStart={2}
            hSpan={9}
            hStartL={1}
            hSpanL={10}
            hSpanM={14}
            hStartS={2}
            hSpanS={14}
            id="machines--cta-card-container"
          >
            <CTACard
              title={CTA.title}
              description={CTA.description}
              image={CTA.photo}
              button={CTA.button}
            />
          </Cell>
        </Grid>
      </section>
      <section>
        <Grid
          id="machines--service-list"
        >
          <Cell
            hStart={3}
            hSpan={12}
            hStartL={3}
            hSpanL={12}
            hStartM={2}
            hSpanM={6}
            hStartS={3}
            hSpanS={12}
            id="machines--service-list-copy"
          >
            <h2>What We Do Best</h2>
          </Cell>
          <Cell
            hStart={3}
            hSpan={12}
            hSpanL={12}
            hSpanM={12}
            hStartS={3}
            hSpanS={12}
          >
            <Grid>
              {services.nodes.map(({
                childMarkdownRemark: {
                  fields: { slug },
                  frontmatter: {
                    title,
                    photo,
                  },
                },
              }) => {
                return (
                  <Cell
                    hSpan={4}
                    hSpanL={4}
                    hSpanM={4}
                    hSpanS={12}
                  >
                    <Card
                      name={title}
                      slug={`/services${slug}`}
                      image={photo}
                    />
                  </Cell>
                )
              })}
            </Grid>
          </Cell>
        </Grid>
      </section>
    </>
  );
});

export default BTFMachines;
